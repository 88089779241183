<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12" v-if="partnerData && partnerData.hidden === 'Y'">
                <v-alert class="elevation-2 mb-1" type="error"
                    >This partner account is not active. This partner will have
                    no access until their account is active.</v-alert
                >
            </v-col>
        </v-row>
        <router-view />
    </v-container>
</template>

<script>
import partnerDataMixin from '../../mixins/partnerDataMixin';
import apiMixin from '../../mixins/apiMixin';

export default {
    name: 'PartnerParent',
    mixins: [apiMixin, partnerDataMixin],

    beforeRouteUpdate(to, from, next) {
        if (to.params.partnerId && to.params.partnerId!==from.params.partnerId) this.get(to.params.partnerId);
        next();
    },
    /**
     * this will set the global partner data.
     * Note that if already loaded it used loaded data
     */
  watch: {
    $route(to, from) {
      this.get(to.params.partnerId);
    },
  },
  /**
   * We keep this create method in the parent because if it is in the mixin component it is called every time the component is used.
   * Hence we end up with many request from the get process.
   */
  created() {
    this.get(this.$route.params.partnerId);
  },
};
</script>
