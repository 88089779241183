<template>
    <v-row dense>
        <v-col cols="12" sm="6" lg="4">
            <PartnerCard
                :data="data"
                :loading="loading"
                v-on:update="refresh"
            />
        </v-col>
        <v-col cols="12" sm="6" lg="4">
            <PartnerCommsCard
                :data="data"
                :loading="loading"
                v-on:update="refresh"
            />
        </v-col>
        <v-col cols="12" sm="6" lg="4">
            <AddressCard :data="data" :loading="loading" :hideActions="true"/>
        </v-col>
        <v-col cols="12" sm="6" lg="4">
            <ContactCard :data="data" :loading="loading" />
        </v-col>

    </v-row>
</template>

<script>
import PartnerCard from '@/components/cards/Partner/PartnerCard';
import PartnerCommsCard from '@/components/cards/Partner/PartnerCommsCard';
import AddressCard from '@/components/cards/AddressCard';
import ContactCard from '@/components/cards/ContactCard';
export default {
    name: 'PartnerHome',
    data: () => ({}),
    methods: {
        refresh() {
            //console.log('refresh');
        },
    },
    components: {
        ContactCard,
        AddressCard,
        PartnerCommsCard,
        PartnerCard,
    },
    computed: {
        loading() {
            return this.$store.state.loading.partner;
        },
        data() {
            if (this.$store.state.data.partner) {
                return this.$store.state.data.partner;
            }
            return [];
        },
    },
};
</script>
